import { SetState, GetState } from "zustand";
import { Store } from "./useStore";

export interface LayoutSlice {
  variant: any | undefined;
  changeVariant: (handle: any) => void;
}

const createLayoutSlice = (set: SetState<Store>, get: GetState<Store>) => ({
  variant: undefined,
  changeVariant: (variant: any) => {
    set((prev) => ({ ...prev, variant }));
  },
});

export default createLayoutSlice;
