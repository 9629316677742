import {
  Box,
  Flex,
  Heading,
  Menu,
  MenuGroup,
  Text,
  MenuList,
} from "@chakra-ui/react";
import React from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import useStore, { Store } from "../../util/store/useStore";
import { Module, Workbook } from "../../util/types";
import { DropdownButton } from "./dropdowns/DropdownButton";
import { DropdownItem } from "./dropdowns/DropdownItem";
import { DropdownList } from "./dropdowns/DropdownList";

const selectors = {
  workbook: (state: Store) => state.workbook,
  viz: (state: Store) => state.viz,
  changeWorkbook: (state: Store) => state.changeWorkbook,
  module: (state: Store) => state.module,
  modules: (state: Store) => state.modules,
  changeModule: (state: Store) => state.changeModule,
};

export const DashDropdown: React.FC = () => {
  const module = useStore(selectors.module);
  const viz = useStore(selectors.viz);
  const workbook = useStore(selectors.workbook);
  const changeWorkbook = useStore(selectors.changeWorkbook);

  const [categorized, setCategorized] = React.useState<
    Record<string, Workbook[]>
  >({});
  const [filler, setFiller] = React.useState<number>(0);
  const [collapsed, setCollapsed] = React.useState<boolean[]>([]);

  const handleWorkbookChange = async (newWorkbook: Workbook) => {
    if (viz === null || viz === undefined) return;
    const book = viz.getWorkbook();
    if (!book) return;

    changeWorkbook(newWorkbook);
    await book.activateSheetAsync(newWorkbook.value);
  };

  const handleCollapse = (index: number) => {
    const copy = [...collapsed];
    copy[index] = !collapsed[index];
    setCollapsed(copy);
  };

  React.useEffect(() => {
    if (module) {
      const { workbooks } = module;
      const parsed: Record<string, Workbook[]> = {};
      let categories = 0;
      workbooks.forEach((book) => {
        if (book.category === "Hidden") return;
        parsed[book.category] = parsed[book.category]
          ? [...parsed[book.category], book]
          : [book];
        if (book.category !== "Main") categories++;
      });
      setCategorized(parsed);
      setCollapsed([...Array(categories)].map(() => true));
      setFiller(1);
    }
  }, [module]);

  return (
    <Box>
      <Menu>
        <DropdownButton heading="Go To" />
        <MenuList
          minWidth="300px"
          maxH="600px"
          overflowY="auto"
          overflowX="hidden">
          <MenuGroup>
            {Object.keys(categorized).map((category, index) =>
              category === "Main" ? (
                categorized[category].map((book) => (
                  <div key={book.value}>
                    <DropdownItem
                      value={book.value}
                      comparison={workbook?.value}
                      label={book.name}
                      callback={() => {
                        handleWorkbookChange(book);
                      }}
                    />
                  </div>
                ))
              ) : (
                <MenuGroup key={category}>
                  <Flex
                    cursor="pointer"
                    mx={4}
                    my={2}
                    onClick={() => handleCollapse(index - filler)}
                    align="center"
                    justify="space-between">
                    <Heading fontSize={16}>{category}</Heading>
                    <Text fontSize="12px">
                      {!collapsed[index - filler] ? (
                        <AiFillCaretDown />
                      ) : (
                        <AiFillCaretRight />
                      )}
                    </Text>
                  </Flex>
                  {!collapsed[index - filler] &&
                    categorized[category].map((book) => (
                      <div key={book.value}>
                        <DropdownItem
                          value={book.value}
                          comparison={workbook?.value}
                          label={book.name}
                          callback={() => {
                            handleWorkbookChange(book);
                          }}
                        />
                      </div>
                    ))}
                </MenuGroup>
              )
            )}
          </MenuGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};

export const ModuleDropdown: React.FC = () => {
  const module = useStore(selectors.module);
  const modules = useStore(selectors.modules);
  const changeModule = useStore(selectors.changeModule);
  const changeWorkbook = useStore(selectors.changeWorkbook);

  const onClick = (newModule: Module) => {
    if (module === newModule) return;

    changeModule(newModule);
    changeWorkbook(newModule.workbooks[0]);
  };

  return (
    <Box>
      <Menu>
        <DropdownButton heading={module ? module.name : ""} />
        <DropdownList>
          {modules?.map((mod) => (
            <DropdownItem
              key={mod.id}
              callback={() => onClick(mod)}
              value={mod}
              comparison={module}
              label={mod.name}
            />
          ))}
        </DropdownList>
      </Menu>
    </Box>
  );
};
