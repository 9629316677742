import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { ModuleDropdown } from "./nav/Dropdown";
import { LeftActions, RightActions } from "./nav/Links";
export const Nav: React.FC = () => {

  return (
    <React.Fragment>
      <Flex bg="#ffffff" className="sticky" px={1} pt={2}>
        <Flex
          flex={1}
          m="auto"
          align="center"
          w={1800}
          display="flex"
          fontFamily="Arial">

          <Box ml="auto">
            <Flex align="center">
              <LeftActions />
              <RightActions />
              <ModuleDropdown />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </React.Fragment>
  );
};
