import React from "react";
import { useFullScreenHandle, FullScreen } from "react-full-screen";
import Viz from "../../pages/viz";
import useStore, { Store } from "../../util/store/useStore";

const selectors = {
  /**
   * Passes the Fullscreen Handler to a global store, for access in components later on.
   */
  changeHandle: (state: Store) => state.changeHandle,
};

export const FullScreenHandler: React.FC = () => {
  /**** State *****/
  const handle = useFullScreenHandle();
  const changeHandle = useStore(selectors.changeHandle);

  /**** Hooks *****/

  /**
   * Globally set the handle
   */
  React.useEffect(() => {
    changeHandle(handle);
  }, [changeHandle, handle]);

  return (
    <FullScreen handle={handle}>
      <Viz />
    </FullScreen>
  );
};
