import { MenuButton, Flex, Text } from "@chakra-ui/react";
import { AiFillCaretDown } from "react-icons/ai";

export const DropdownButton: React.FC<{ heading: string }> = ({ heading }) => {
  return (
    <MenuButton
      cursor="pointer"
      alignItems="center"
      boxShadow="sm"
      as={Flex}
      width={heading === "Players & Profits" ? "155px" : "120px"}
      px={3}
      mx={2}
      maxHeight="30px"
      height="30px"
      border="1px solid rgb(204, 204, 204)"
      background="#ffffff"
      _hover={{
        boxShadow: "md",
      }}>
      <Flex align="center" justify="space-between">
        <Text fontSize="13px" fontWeight="bold">
          {heading}
        </Text>
        <Text fontWeight="bold" fontSize="10px" ml={3}>
          <AiFillCaretDown />
        </Text>
      </Flex>
    </MenuButton>
  );
};
