import React from 'react';
import useStore, { Store } from '../store/useStore';

const selectors = {
    viz: (state: Store) => state.viz,
};

const time = 1000 * 60 * 60; // 1 hour

export const useRefresh = () => {
    const viz = useStore(selectors.viz);

    const refresh = React.useCallback(async () => {
        if (!viz) return;
        console.log('Refreshing');

        await viz.refreshDataAsync();
    }, [viz]);

    React.useEffect(() => {
        const refreshInterval = setInterval(refresh, time);

        return () => clearInterval(refreshInterval);
    }, [refresh]);
};
