import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import { Nav } from "../components/Nav";
import { live } from "../util/config";
import useStore, { Store } from "../util/store/useStore";
import { useRefresh } from '../util/hooks/useRefresh';

declare const window: any;
const { tableau } = window;

/**
 * Zustand
 */
const selectors = {
  module: (state: Store) => state.module,
  viz: (state: Store) => state.viz,
  workbook: (state: Store) => state.workbook,
  changeViz: (state: Store) => state.changeViz,
  variant: (state: Store) => state.variant,
  changeWorkbook: (state: Store) => state.changeWorkbook,
};

const Viz: React.FC = () => {
  const module = useStore(selectors.module);
  const viz = useStore(selectors.viz);
  const workbook = useStore(selectors.workbook);
  const changeWorkbook = useStore(selectors.changeWorkbook);
  const changeViz = useStore(selectors.changeViz);

  const variant = useStore(selectors.variant);

  const handleURL = React.useCallback(() => {
    if (
      variant === "base" ||
      variant === "md" ||
      variant === "sm" ||
      variant === "lg"
    ) {
      changeWorkbook({ value: "Menu" } as any);
      return "MobileSlotfocus";
    }
    return live ? module?.live_url : module?.demo_url;
  }, [changeWorkbook, module?.demo_url, module?.live_url, variant]);

  const full = React.useMemo(() => {
    return variant === "xl" || variant === "2xl";
  }, [variant]);

  React.useEffect(() => {
    if (viz) viz.dispose();

    changeViz(
      new tableau.Viz(
        document.getElementById("container"),
        `https://10az.online.tableau.com/t/slotfocus/views/${handleURL()}/${
          workbook?.value
        }?:origin=card_share_link&:embed=y&:render=false&:highdpi=false`,
        {
          hideTabs: true,
          width: "100%",
          height: "100%",
          hideToolbar: true,
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleURL]);
  
  useRefresh();

  return (
    <Flex flexDir="column" id="viz">
      {full && <Nav />}
      <Flex align="center" justify="center" h="100vh" pt={!full ? "0" : ""}>
        <Box
          height={!full ? "100vh" : "calc(100vh - 64px) !important"}
          flex={2}
          id="container"
        />
      </Flex>
    </Flex>
  );
};

export default Viz;
