import { GetState, SetState } from "zustand";
import { Module, Workbook } from "../types";
import { Store } from "./useStore";

/**
 * Need the active dashboard, module, and viz.
 */
export interface DataSlice {
  workbook: Workbook | undefined;
  module: Module | undefined;
  modules: Module[] | undefined;
  viz: any;
  changeWorkbook: (workbook: Workbook) => void;
  changeModule: (module?: Module) => void;
  changeModules: (modules: Module[]) => void;
  changeViz: (viz: any) => void;
}

const createDataSlice = (set: SetState<Store>, get: GetState<Store>) => ({
  module: undefined,
  modules: undefined,
  workbook: undefined,
  viz: undefined,
  changeModule: (module?: Module) => {
    set((prev) => ({
      ...prev,
      module,
    }));
  },
  changeModules: (modules: Module[]) => {
    set((prev) => ({
      ...prev,
      modules,
    }));
  },
  changeWorkbook: (workbook: Workbook) => {
    set((prev) => ({
      ...prev,
      workbook,
    }));
  },
  changeViz: (viz: any) => {
    set((prev) => ({
      ...prev,
      viz,
    }));
  },
});

export default createDataSlice;
