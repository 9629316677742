import React from "react";
import { initializeApp } from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "./App.css";
import { collection, getFirestore } from "firebase/firestore";
import { moduleConverter } from "./util/config";
import useStore, { Store } from "./util/store/useStore";
import { RenderHandler } from "./components/handlers/RenderHandler";
import { Routes, Route } from "react-router-dom";
import { AdminSignup } from './components/admin/Signup';

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyDpe6kX3nJR-4MqNWLw0wL5GhII80DGVyA",
  authDomain: "slotfocusdemo.firebaseapp.com",
  projectId: "slotfocusdemo",
  storageBucket: "slotfocusdemo.appspot.com",
  messagingSenderId: "1026198967799",
  appId: "1:1026198967799:web:95769197fad1beee4e4af2",
});

const selectors = {
  changeModules: (state: Store) => state.changeModules,
};

const App = () => {
  const changeModules = useStore(selectors.changeModules);
  const ref = collection(getFirestore(firebaseApp), "modules").withConverter(
    moduleConverter
  );
  const [modules] = useCollectionData(ref);

  React.useEffect(() => {
    if (modules) changeModules(modules);
  }, [changeModules, modules]);

  return (
    <Routes>
      <Route path="/" element={modules ? <RenderHandler /> : null} />
      <Route path="admin" element={<AdminSignup />} /> 
    </Routes>
  );
};

export default App;
