import { MenuItem } from "@chakra-ui/react";

export const DropdownItem: React.FC<{
  value: any;
  comparison: any;
  label: string;
  callback: (item: any) => void;
}> = ({ value, comparison, label, callback }) => {
  return (
    <MenuItem
      px={6}
      onClick={() => callback(value)}
      fontSize="15px"
      _focus={{ background: "white", color: "black" }}
      _hover={{ background: "#fcedf0", color: "black" }}
      value={value}
      fontWeight={comparison === value ? "bold" : "regular"}
      color={comparison === value ? "white" : "black"}
      background={comparison === value ? "#bc1e3e" : "transparent"}>
      {label}
    </MenuItem>
  );
};
