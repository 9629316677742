import {
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { Module } from "./types";

const location = window.location.href;
const parsed = location.substring(8, 12); // parse out demo or live from https://

export const live = parsed === "live";

export const moduleConverter: FirestoreDataConverter<Module> = {
  toFirestore(module: WithFieldValue<Module>): DocumentData {
    return module;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Module {
    const data = snapshot.data(options);
    return {
      ...(data as Module),
      id: snapshot.id,
    };
  },
};
