import {
    Box,
    Stack,
    Heading,
    FormLabel,
    Input,
    Flex,
    Button,
    useToast,
} from '@chakra-ui/react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import React from 'react';

/**
 * Render the Signup page for an Admin account
 */
export const AdminSignup: React.FC = () => {
    /**
     * Email and password state
     */
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const toast = useToast();

    /**
     * Register to Firebase.
     */
    const handleSubmit = async () => {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
            .then(({ user }) =>
                toast({
                    title: 'Success',
                    description: `${user.email} was successfully created.`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                })
            )
            .catch((error) =>
                toast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                })
            );
    };

    return (
        <Box h="100vh" p="6rem" maxW={800} m="auto" pt="12rem">
            <Stack spacing={4}>
                <Heading size="md">Sign Up</Heading>
                <Box>
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="email"
                        onChange={({ target }: any) => setEmail(target.value)}
                    />
                </Box>
                <Box>
                    <FormLabel>Password</FormLabel>
                    <Input
                        type="password"
                        onChange={({ target }: any) =>
                            setPassword(target.value)
                        }
                    />
                </Box>
                <Flex justify="center">
                    <Button size="sm" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Flex>
            </Stack>
        </Box>
    );
};
