import { useBreakpoint } from "@chakra-ui/react";
import React from "react";
import UserPortal from "../../pages/portal";
import useStore, { Store } from "../../util/store/useStore";
import { FullScreenHandler } from "./FullScreenHandler";

const selectors = {
  changeVariant: (state: Store) => state.changeVariant,
  changeWorkbook: (state: Store) => state.changeWorkbook,
};

export const RenderHandler: React.FC = () => {
  const [clicked, setClicked] = React.useState<boolean>(false);

  const changeVariant = useStore(selectors.changeVariant);
  const changeWorkbook = useStore(selectors.changeWorkbook);

  const value: any = useBreakpoint();

  const [mounted, setMounted] = React.useState<boolean>(false);

  React.useEffect(() => setMounted(true), []);

  React.useEffect(() => {
    changeVariant(value);
  }, [changeVariant, value]);

  React.useEffect(() => {
    if (!mounted) return;
    if (
      value === "base" ||
      value === "md" ||
      value === "sm" ||
      value === "lg"
    ) {
      changeWorkbook({ value: "Menu" } as any);
      setClicked(true);
    }
  }, [changeWorkbook, mounted, value]);

  return clicked === true ? (
    <FullScreenHandler />
  ) : (
    <UserPortal setClicked={setClicked} />
  );
};
