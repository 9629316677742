import { Box, Flex, useToast, Text } from "@chakra-ui/react";
import React from "react";
import useStore, { Store } from "../../util/store/useStore";
import {
  BiReset,
  BiExport,
  // BiCommentMinus,
  BiUndo,
  BiRedo,
  BiFullscreen,
  // BiPlus,
} from "react-icons/bi";

const selectors = {
  viz: (state: Store) => state.viz,
  handle: (state: Store) => state.handle,
};

interface HeadingLinkProps {
  /**
   * Callback for what happens when Link is clicked
   */
  handleClick: () => void;
  /**
   * What text to display
   */
  text: string;
  /**
   * What icon to display
   */
  icon: JSX.Element | null;
  /**
   * Whether or not to allow the link to be clicked
   */
  disabled?: boolean;
}

/**
 * Renders a link that is meant to go in the Navbar.
 */
export const HeadingLink: React.FC<HeadingLinkProps> = ({
  handleClick,
  text,
  icon,
  disabled,
}) => {
  return (
    <Box mx={2} opacity={!!disabled ? 0.5 : 1}>
      <Flex
        gridGap={1}
        color="#1E457D"
        fontSize="13px"
        align="center"
        cursor={!!disabled ? "not-allowed" : "pointer"}
        onClick={() => {
          if (!!disabled) return;
          else handleClick();
        }}
        _hover={{ borderBottom: "1px solid #1E457D ", fontWeight: "bold" }}>
        {icon}
        <Text>{text}</Text>
      </Flex>
    </Box>
  );
};

export const LeftActions: React.FC = () => {
  const viz = useStore(selectors.viz);
  const handle = useStore(selectors.handle);

  const toast = useToast();

  return (
    <React.Fragment>
      <HeadingLink
        text="Reset"
        disabled={viz === undefined}
        icon={<BiReset />}
        handleClick={async () => {
          if (viz) await viz.revertAllAsync();
        }}
      />
      <HeadingLink
        text="Export"
        disabled={viz === undefined}
        icon={<BiExport />}
        handleClick={() => {
          if (viz) viz.showDownloadDialog();
          else
            toast({
              title: "Error",
              status: "error",
              isClosable: true,
              description: "Something went wrong loading the Export Dialog",
            });
        }}
      />

      <HeadingLink
        text="Fullscreen"
        icon={<BiFullscreen />}
        handleClick={async () => {
          handle.active ? await handle.exit() : await handle.enter();
        }}
      />
    </React.Fragment>
  );
};

export const RightActions: React.FC = () => {
  const viz = useStore(selectors.viz);

  /**
   * Counts the undo / redo actions
   */
  const [count, setCount] = React.useState<number>(0);

  return (
    <Box ml={2} pl={3} borderLeft="1px solid rgba(0,0,0,0.1)">
      <Flex align="center">
        <HeadingLink
          text="Undo"
          disabled={viz === undefined}
          icon={<BiUndo />}
          handleClick={async () => {
            if (viz) await viz.undoAsync();
            setCount(count + 1);
          }}
        />
        <HeadingLink
          text="Redo"
          icon={<BiRedo />}
          disabled={count === 0}
          handleClick={async () => {
            if (viz) await viz.redoAsync();
            if (count > 0) setCount(count - 1);
          }}
        />
      </Flex>
    </Box>
  );
};
