import { MenuList } from "@chakra-ui/react";
import React from "react";

export const DropdownList: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <MenuList minWidth="300px" maxH="600px" overflowY="auto" overflowX="hidden">
      {children}
    </MenuList>
  );
};
