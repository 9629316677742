import { Box, Button, Flex, Heading, Image } from "@chakra-ui/react";
import React from "react";
import useStore, { Store } from "../util/store/useStore";
import { Module } from "../util/types";

/**
 * Zustand Selectors
 */
const selectors = {
  modules: (state: Store) => state.modules,
  changeModule: (state: Store) => state.changeModule,
  changeWorkbook: (state: Store) => state.changeWorkbook,
};

/**
 * Render the Portal welcome page.
 * @param setClicked - function that sets state in the handler to change the render view.
 */
const UserPortal: React.FC<{
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setClicked }) => {
  /**
   * Zustand
   */
  const modules = useStore(selectors.modules);
  const changeModule = useStore(selectors.changeModule);
  const changeDashboard = useStore(selectors.changeWorkbook);

  /**
   * Render a Styled Button to select the initial module.
   * @param module - The module being selected
   */
  const StyledButton: React.FC<{ module: Module }> = ({ module }) => {
    /**
     * Update our store and change the render handler
     */
    const onClick = () => {
      changeModule(module);
      changeDashboard(module.workbooks[0]);
      setClicked(true);
    };

    return (
      <Button
        background="transparent"
        _hover={{ background: "white" }}
        _active={{ background: "white" }}
        border="1px solid rgb(209, 212, 217)"
        color="#303031"
        onClick={onClick}
        px={8}
        boxShadow="rgb(3 27 78 / 6%) 0px 2px 4px !important"
        py={10}>
        <Image
          src={module.img_src}
          width="163px"
          height="61px"
          alt={module.name}
        />
      </Button>
    );
  };

  return (
    <React.Fragment>
      <Flex align="center" justify="center" minH="100vh" p={4}>
        <Flex
          maxW={1440}
          p={4}
          justify="center"
          flexDir="column"
          align="center"
          mb="2rem"
          flex={1}
          rounded="lg"
          height={["420px", "628px"]}>
          <Box textAlign="center">
            <Heading
              mb="1rem"
              fontSize="72px"
              fontWeight={900}
              fontFamily="sans-serif">
              Welcome
            </Heading>
            <Heading
              fontFamily="sans-serif"
              pl={2}
              fontSize={32}
              fontWeight="medium"
              color="#525666"
              mb="2rem">
              Select a module to start.
            </Heading>
            <Box>
              <Flex
                align="center"
                justify="center"
                fontFamily="sans-serif"
                wrap="wrap"
                py={2}
                gridGap={4}>
                {modules?.map((module) => (
                  <StyledButton key={module.name} module={module} />
                ))}
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default UserPortal;
