import { GetState, SetState } from "zustand";
import { FullScreenHandle } from "../types";
import { Store } from "./useStore";

export interface ScreenSlice {
  handle: FullScreenHandle;
  changeHandle: (handle: FullScreenHandle) => void;
}

const createScreenSlice = (set: SetState<Store>, get: GetState<Store>) => ({
  handle: {
    active: false,
    enter: async () => {},
    exit: async () => {},
    node: null,
  },
  changeHandle: (handle: FullScreenHandle) => {
    set((prev) => ({ ...prev, handle }));
  },
});

export default createScreenSlice;
