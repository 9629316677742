import create from "zustand";
import createDataSlice, { DataSlice } from "./createDataSlice";
import createLayoutSlice, { LayoutSlice } from "./createLayoutSlice";
import createScreenSlice, { ScreenSlice } from "./createScreenSlice";

export type Store = DataSlice & ScreenSlice & LayoutSlice;

const useStore = create<Store>((set, get) => ({
  ...createDataSlice(set, get),
  ...createScreenSlice(set, get),
  ...createLayoutSlice(set, get),
}));

export default useStore;
